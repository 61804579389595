@import url('./main-job.css');

.App {
    text-align: left;
    margin: 20px 150px;
}

.App-logo {
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        width: 200px;
        height: auto;
    }

    .trade-logo {
        width: 130px;
        height: auto;
    }
}

.App-header {
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.link-color {
    color: #75bb3e !important;
    text-decoration: none;
}

.login-header {
    float: left;
    font-size: 24px;
    font-weight: normal;
}

.signup-header {
    float: right;
    font-size: 18px;
    font-weight: normal;
    margin-top: 5px;
}

.modal-content {
    border: none;
}

iframe#myId {
    border: 0;
}

.thanks {
    text-align: center;
    margin-top: 100px;
    display: block;
}

.thanks-title {
    font-size: 36px;
    color: #676767;
    margin-bottom: 5px;
    font-weight: 500;
    text-align: center;
    margin-top: 0px;
}

.thanks-sign {
    margin-top: 10px !important;
    float: right;
    width: 100px !important;
}

.thanks-text {
    color: #999999;
    margin-top: 0;
    line-height: 1.9;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px !important;
}

.thanks-btn {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    background: #79c24b;
    padding: 8px 20px;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    top: 10px;
}

.action-btn {
    color: #fff !important;
    top: 30px;
    font-size: 18px;
    background: #79c24b !important;
    padding: 8px 25px !important;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    width: 130px;
    text-align: center;
    border-radius: 5px;
    margin: 20px 10px;
    font-weight: 700;
}

.action-btn::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: none !important;
    top: 0;
    right: 0px !important;
}

.trade-btn {
    color: #fff !important;
    top: 0px;
    font-size: 18px;
    background: #79c24b !important;
    padding: 8px 25px !important;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    width: 130px;
    text-align: center;
    margin: 20px 10px;
    font-weight: 700;
    border: 0px !important;
}

.trade-on {
    color: #fff;
    top: 0px;
    font-size: 18px;
    background: #79c24b;
    padding: 8px 25px !important;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    width: 130px;
    text-align: center;
    margin: 20px 10px;
    font-weight: 700;
}

.trade-off {
    color: #333;
    top: 0px;
    font-size: 18px;
    background: lightgrey;
    padding: 8px 25px !important;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    width: 130px;
    text-align: center;
    margin: 20px 10px;
    font-weight: 700;
}

.trade-on::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18.3px 0 24px 20px !important;
    border-color: transparent transparent transparent #79c24b;
    top: 0;
    left: -20px;
    transform: rotate(180deg);
}

.trade-off::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18.3px 0 24px 20px !important;
    border-color: transparent transparent transparent lightgrey;
    top: 0;
    left: -20px;
    transform: rotate(180deg);
}

.trade-btn::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18.3px 0 24px 20px !important;
    border-color: transparent transparent transparent #79c24b;
    top: 0;
    right: -20px;
}

.trade-btn:hover {
    background: #fff;
    border: 1px solid #79c24b;
    color: #79c24b;
}

.tracker-style {
    width: 100%;
    height: 15px;
    margin: 15px auto;
    border-radius: 20px;
    background: lightgrey;
    margin-top: 70px;
}

.inner-tracker {
    background: #79c24b;
    border-radius: 20px;
    height: 100%;
}

.job1 {
    display: flex;
    margin-top: 40px;
}

fieldset.timeline_one {
    background: white;
    border: 0 none;
    border-radius: 0px;
    padding: 0px 30px;
    box-sizing: border-box;
    width: 55%;
    margin: 0 10%;
    transform: scale(1);
    position: absolute;
    opacity: 1;
    display: block;
}

.thanks-title span {
    color: #12a2de;
    float: left;
    width: 100%;
    margin-bottom: 40px;
}

.needSearch {
    margin: 0;
    border: 0;
    border-bottom: 1px solid lightgrey !important;
    border-radius: 0;
    margin-top: 20px;
}

.bootstrap-select > select.bs-select-hidden, select.bs-select-hidden, select.selectpicker.specialStyle {
    display: block !important;
}

.group_of_text_input {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px auto;
    max-width: 55%;
}

.inputs_group input {
    padding: 8px 10px;
    border: 1px solid #e7e7e7;
    border-radius: 0px;
    margin: 30px auto;
    width: 100%;
    box-sizing: border-box;
    color: #000;
    font-size: 13px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.job1 h5 {
    margin-right: 15px;
    font-size: 12px;
    background: #f5f5f5;
    padding: 8px 20px;
    font-weight: 600;
    margin-bottom: 0;
    padding-right: 5px;
}

.thanks-btn::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18.3px 0 16px 20px !important;
    border-color: transparent transparent transparent #79c24b;
    top: 0;
    right: -20px;
}

button.skip-style {
    float: left;
    width: 5%;
    margin: 0px 48%;
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
    margin-bottom: 20px;
}

.thanks a.link {
    width: auto;
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
    padding: 0;
    font-size: 16px;
}
.thanks a.link::after{
    background: none;
    border: 0;
}

.search-btn {
    color: #fff !important;
    top: 0px;
    font-size: 18px;
    background: #79c24b !important;
    padding: 8px 25px !important;
    text-decoration: none;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    width: 160px;
    text-align: center;
    border-radius: 5px;
    margin: 20px 10px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 40px;
}

.search-btn::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border: none !important;
    top: 0;
    right: 0px !important;
}

.upload_preview {
    background: #e8e8e8;
    padding: 10px !important;
    margin: 7px;
}

button.back-style {
    float: left;
    width: 10%;
    margin: 0px 45%;
    border: none;
    background: none;
    text-decoration: underline;
    cursor: pointer;
    color: #007bff;
}

.button-style {
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: left 10px center;
    background-color: #75bb3e;
    font-size: 16px;
    outline: 0;
    cursor: pointer;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-weight: bold;
}

.captcha {
    font-size: 15px;
    font-weight: 300;
    line-height: 150%;
    color: #637282;
    text-align: left;
}

.remember-text {
    float: left;
    margin-top: 10px;
}

.sign-btn {
    width: 100px;
    float: right;
    margin: 0px;
}

.line-or {
    float: left;
    width: 45%;
    margin-top: 29px;
}

.or-text {
    float: left;
    width: 10%;
}

.forget-text {
    float: left;
    width: 100%;
    text-align: left;
    margin-top: 10px;
}

.pull-left {
    width: 50%;
    float: left;
    margin-top: 190px;
    padding-left: 75px;
}

.pull-right {
    width: 50%;
    float: right;
    margin-top: 85px;
    text-align: center;
    padding: 10px 70px;
}

.sign-left {
    width: 40%;
    float: left;
    text-align: center;
}

.sign-right {
    width: 53%;
    float: right;
    text-align: left;
    margin: 40px 30px;
}

.form-style {
    color: #999;
    border-radius: 3px;
    margin-bottom: 15px;
    background: #f2f2f2;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 15px 30px;
    margin-top: 30px;
}

.form-style h1 {
    color: #696969;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 20px !important;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    text-align: left !important;
}

.form-style input {
    height: 41px;
    width: 49%;
    background: #fff;
    box-shadow: none !important;
    border: 2px solid #e0e0e0;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 10px;
}

.login-form input {
    height: 41px;
    width: 100%;
    background: #fff;
    box-shadow: none !important;
    border: 2px solid #e0e0e0;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 10px;
}

.error-style {
    border: 2px solid red !important;
    margin-bottom: 0px !important;
}

.valid-error {
    color: red;
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
}

.valid-error2 {
    color: red;
    text-align: left;
    float: left;
    padding-left: 5px;
    margin-bottom: 10px;
    height: 15px;
    font-size: 12px;
}

.valid-error3 {
    color: red;
    text-align: left;
    float: left;
    margin-bottom: 10px;
    font-size: 12px;
}

.valid-error4 {
    color: red;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    font-size: 12px;
}

.form-style select {
    height: 41px;
    background: #fff;
    box-shadow: none !important;
    border: 2px solid #e0e0e0;
    margin-bottom: 20px;
    width: 49%;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 10px;
}

.sign-title {
    color: #686868;
    margin-bottom: 0;
    font-size: 38px;
    font-weight: 400;
}

.sign-text {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 24px;
    color: #676767;
    font-weight: 400;
    line-height: 1.5;
}

.sign-button {
    font-size: 16px;
    font-weight: bold;
    background: #73c53c;
    border: none;
    min-width: 140px;
    border: 1px solid #73c53c;
    transition: 0.4s all;
    color: #fff;
    height: 41px;
    outline: 0;
    cursor: pointer;
}

.sign-right ul {
    padding-left: 0px;
}

.sign-right ul li {
    list-style: none;
    color: #939393;
    font-weight: 400;
    line-height: 1.5;
}

.sign-right ul li::before {
    content: '✓';
    font-size: 21px;
    font-weight: bold;
    margin-right: 10px;
    color: rgb(117, 196, 65);
}

.modal-box {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.modal-content {
    padding: 20px;
}

.modal-dialog h2 {
    float: left;
    width: 90%;
    margin: 0px;
    text-align: left;
}

.modal-dialog a.my-2 {
    position: absolute;
    width: 5%;
    font-size: 24px;
    color: grey;
    font-weight: bolder;
    text-decoration: none;
    line-height: 24px;
    text-align: right;
    right: 15px;
}

.modal-dialog .content {
    float: left;
    width: 100%;
    padding: 30px 0px;
    text-align: left;
}

.modal-dialog input {
    height: 35px;
    width: 100%;
    background: #fff;
    box-shadow: none !important;
    border: 2px solid #e0e0e0;
    margin-bottom: 20px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    border-radius: 3px;
    font-size: 16px;
    padding-left: 10px;
}

.modal-dialog form {
    text-align: left;
}

.job1 h5 i.fa.fa-times.cut_need {
    cursor: pointer;
}

.modal-body h2, .modal-header h2{
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    color: #75bb3e;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
    background: rgba(0, 0, 0, 0.5) !important;
}

.modal-open .modal .modal-dialog {
    padding: 0px;
}

.modal-body form {
    text-align: center;
}

.navbar-light .navbar-nav .nav-link {
    cursor: pointer;
}

.nav-pills .nav-link {
    cursor: pointer;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mobile-nav {
    display: block;
}

@media (min-width: 1200px) and (max-width: 1400px) {
    .App {
        margin: 20px 100px;
    }

    .sign-left {
        width: 45%;
    }

    .sign-right {
        width: 48%;
    }
}

@media (min-width: 1020px) and (max-width: 1200px) {
    .App {
        margin: 20px 70px;
    }

    .pull-left iframe#myId {
        width: 100%;
    }

    .sign-left {
        width: 47%;
    }

    .sign-right {
        width: 45%;
    }

    /*.valid-error2 {
        font-size: 15px;
    }

    .valid-error3 {
        font-size: 15px;
    }

    .valid-error4 {
        font-size: 15px;
    }*/
}

@media (min-width: 800px) and (max-width: 1020px) {
    .App {
        margin: 20px
    }

    .pull-left {
        display: none
    }

    .pull-right {
        width: 60%;
        float: none;
        margin-top: 85px !important;
        text-align: center;
        padding: 10px 70px;
        margin: auto;
    }

    .sign-left {
        width: 50%;
        float: none;
        margin: auto;
    }

    .sign-right {
        width: 90%;
        float: none;
        margin: auto;
        margin-top: 70px;
    }

    .valid-error2 {
        font-size: 15px;
    }

    .valid-error3 {
        font-size: 15px;
    }

    .valid-error4 {
        font-size: 15px;
    }
}

@media (max-width: 880px) {
    .sign-left {
        width: 65%;
        float: none;
        margin: auto;
    }

    .sign-right {
        width: 90%;
        float: none;
        margin: auto;
        margin-top: 70px;
    }

    .valid-error2 {
        font-size: 15px;
    }

    .valid-error3 {
        font-size: 15px;
    }

    .valid-error4 {
        font-size: 15px;
    }
}

@media (max-width: 800px) {
    .App {
        margin: 20px
    }

    .pull-left {
        display: none
    }

    .pull-right {
        width: 70%;
        float: none;
        margin-top: 85px !important;
        text-align: center;
        padding: 10px 70px;
        margin: auto;
    }

    .valid-error2.col-md-6 {
        width: 50%;
    }

    fieldset.timeline_one {
        width: 90%;
        margin: 0px;
    }
}

@media (max-width: 700px) {
    .App {
        margin: 20px
    }

    .pull-left {
        display: none
    }

    .pull-right {
        width: 80%;
        float: none;
        margin-top: 85px !important;
        text-align: center;
        padding: 10px 70px;
        margin: auto;
    }

    .sign-left {
        width: 70%;
        float: none;
        margin: auto;
    }

    .group_of_text_input .valid-error {
        text-align: center;
    }
}

@media (max-width: 630px) {
    .App {
        margin: 20px
    }

    .pull-left {
        display: none
    }

    .pull-right {
        width: 100%;
        float: none;
        margin-top: 85px !important;
        text-align: center;
        padding: 10px 70px;
        margin: auto;
    }

    .sign-left {
        width: 90%;
        float: none;
        margin: auto;
    }

    .group_of_text_input {
        margin: 10px;
        max-width: 100%;
    }
}

@media (max-width: 520px) {
    .valid-error2 {
        font-size: 14px;
    }

    .valid-error3 {
        font-size: 14px;
    }

    .valid-error4 {
        font-size: 14px;
    }

    .modal-dialog {
        max-width: 90%;
        margin: auto;
    }

    .modal-dialog h2 {
        font-size: 28px;
    }

    .modal-dialog a.my-2 {
        margin: 0px !important;
        margin-top: 5px !important;
    }

    .pull-right {
        padding: 10px 20px;
        margin-top: 30px !important;
    }

    .form-style {
        padding: 20px;
    }

    header.App-header {
        text-align: center;
    }

    .thanks-sign {
        float: none;
    }

    button.back-style {
        width: 30%;
        margin: 0px 35%;
    }
}

@media (max-width: 400px) {
    .thanks {
        margin-top: 30px;
        height: auto;
    }

    .thanks h2 {
        font-size: 24px;
    }

    .trade-off {
        font-size: 14px;
        padding: 8px 16px !important;
        width: 94px;
    }

    .trade-off::after {
        border-width: 18.3px 0 19px 20px !important;
    }

    .trade-on {
        font-size: 14px;
        padding: 8px 16px !important;
        width: 94px;
    }

    .trade-on::after {
        border-width: 18.3px 0 19px 20px !important;
    }

    .trade-btn {
        font-size: 14px;
        padding: 8px 16px !important;
        width: 94px;
    }

    .trade-btn::after {
        border-width: 18.3px 0 19px 20px !important;
    }

    button.skip-style {
        width: 10%;
        margin: 0px 45%;
    }
}

@media (max-width: 320px) {
    .login-header {
        font-size: 20px;
    }

    .signup-header {
        margin-top: 2px;
    }

    .form-style h1 {
        font-size: 28px;
    }

    .form-style {
        padding: 10px;
    }

    .sign-title {
        font-size: 30px;
    }

    .sign-text {
        font-size: 20px;
    }

    fieldset.timeline_one {
        padding: 0px;
    }

    section.profile {
        padding: 0%;
    }

    .addAlert {
        padding: 3px 6px;
    }
}
