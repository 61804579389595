.pagination li {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    background-color: #FFF;
}

.pagination li.active {
    background-color: #78bd43;
}
.pagination a{
    color: #6a6a6a;
}
.pagination li.active a{
    color: white;
}

.pagination li:hover:not(.active) {background-color: #ddd;}


.pagination li {
    transition: background-color .3s;
}

.pagination li {
    border: 1px solid #ddd; /* Gray */
}

.profile_user_login.sort{
    z-index: 0;
    width: 250px;
}

.profile_user_login.sort ul {
    margin-bottom: 0;
}
.profile_user_login.sort ul li a {
    padding: 5px 16px;
}