/* job4job css */


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
}

body{
background: #f4f4f4;
}

#modal-feedback textarea
{
    padding:5px 10px;
}
.savedSuccess{
    display: flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 100px;
    color: #76bd43;
    font-size: 14px;
    font-weight: 600;
    padding: 0px 40px;
    line-height: 2.5;
    border-radius: 100px;
}
.tradeBtn{
    background: #76bd43;
    color: white !important;
    padding: 0px 30px !important;
    border-radius: 100px;

}

.bgWhite{
    background: white;
    overflow: hidden;
}

.user_thumb {
    max-height: 80px;
    max-width: 80px;
}

.uppercase {
    text-transform: uppercase;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
    font-size: 14px;
}

.editable td .form-group {
    margin-bottom: 0;
}

/** notification **/
.notification_table table {
    width: 100%;
}

.notification_table tr {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

#tab2 button.remove_row {
    border: none;
    background: red;
    padding: 4px 8px;
    color: #fff;
}

/** end **/
/*** profile work **/
.profile .reviews .reviews_content {
    width: 70%;
}

/**** settings ***/
.avatar-wrapper {

    height: 200px;
    width: 200px;
    margin: 50px auto;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 1px 1px 15px -5px black;
    transition: all .3s ease;
}

.avatar-wrapper .edit_upload {
    position: relative;
}

.avatar-wrapper:hover {
    transform: scale(1.05);
    cursor: pointer;
}

.avatar-wrapper .upload-button {
    display: none;
}

.avatar-wrapper:hover .upload-button {
    display: block;

}

.avatar-wrapper .upload-button .fa {
    background: #17a8dd;
    color: white;
    border-radius: 100px;

    padding: 8px;
    font-weight: 100;
    font-size: 16px;
}


.avatar-wrapper:hover .profile-pic {
    opacity: .5;
}


.avatar-wrapper .profile-pic {
    height: 100%;
    width: 100%;
    transition: all .3s ease;
}

.avatar-wrapper .profile-pic:after {
    font-family: FontAwesome;
    content: "\f007";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    font-size: 190px;
    background: #ecf0f1;
    color: #34495e;
    text-align: center;
}

.avatar-wrapper .upload-button {
    position: absolute;
    top: 30%;
    text-align: center;

    width: 100%;
    font-size: 25px;
}

.avatar-wrapper .upload-button .fa-arrow-circle-up {
    position: absolute;
    font-size: 234px;
    top: -17px;
    left: 0;
    text-align: center;
    opacity: 0;
    transition: all .3s ease;
    color: #34495e;
}

.avatar-wrapper .upload-button:hover .fa-arrow-circle-up {
    opacity: .9;
}

/*** end ***/
/*** setting ***/
.setting_table table {
    width: 100%;
}

.setting_table tr {
    border-top: 1px solid #e3e3e3;

}

.setting_table td:nth-child(1) {
    width: 33%;
    font-weight: bold;
    color: #6c6c6c;
}

.setting_table td:not(:first-child) {
    text-align: left;
    color: #777777;
}

.setting_table td .form-group {
    margin-bottom: 0;
}

/** end***/
.timeline_one .trade_btn:hover {
    box-shadow: none !important;
    background: transparent !important;
    color: #73c53c !important;
}

/** user profile ***/
.manage_job_bg {
    border: 1px solid #e3e3e3;
}

#tab4 a.add_needs {
    background: #72c244;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: #6a6a6a;
}

.add_needs .fa {
    color: white;
}

span.coming_soon {
    background: darkgray;
    padding: 8px 15px;
    color: #fff;
    text-transform: uppercase;
}

/** end ***/
.show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff;
}

section.select_search .bootstrap-select {
    background: #fff;
    border-radius: 0;
}

section.select_search .dropdown-toggle {
    background: #fff;
    padding: 10px;
    border-radius: 0;
}

.sidenav ul li a {
    text-decoration: none;
    font-size: 14px;
}

.nav_section {
    background-color: #ffffff;
    color: #000;
    line-height: 3em;
    position: fixed;
    width: 100%;
    z-index: 1;
    display: block;
    top: 0;
    transition: top 0.2s ease-in-out;
}

.profile-tab{
    width: 32%;
    padding: 0% 5% !important;
}

.profile-tab-active{
    width: 32%;
    padding: 0% 5% !important;
    color: white;
    background: #72c244;
    border: #72c244;
}

.nav_section .navbar-nav .nav-item .nav-link {
    text-transform: uppercase;
    font-weight: 700;
    color: #4a4a4a;
}

span.navbar-toggler-icon i {
    color: #0ba5dc !important;
    font-size: 30px;
}

.sidenav .nav {
    display: block !important;
    margin: 15px;
    padding: 5%;
}

.nav_section nav.navbar {
    padding-left: 0;
    padding-right: 0;
}

.bottom_nav {
    display: none;
}

.sidenav .nav li {
    line-height: 35px;
}

.sidenav .nav li a {
    color: #000;
}

.sidenav .nav li.active a {
    color: #0ca4de;
}

/** notification **/
.notification {
    color: #000;
    text-decoration: none;
    padding: 0px 10px;
    position: relative;
    display: inline-block;
    border-radius: 2px;
	vertical-align:middle;
}

.notification .badge {
    position: absolute;
    top: 8px;
    right: 8px;
    padding: 5px;
    font-size: 7px;
    border-radius: 50%;
    background-color: red;
    color: white;
}

section.sidebar_custom {
    background: #f4f4f4;

}

.sidebar_custom {
    margin-top: 100px;
}

.right_section {
    display: flex;
    flex-wrap: wrap;
    margin: 25px 0px;

}

.manage {
    flex: 1 1 50%;
}

/*** my active ***/
.list_of_needs {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 15px;
    box-shadow: 8px 5px 14px -5px #ccc;
    background: #fff;
    padding: 5%;
    min-height: 315px;
}

.list_of_needs p {
    font-weight: 700;
}

.list_of_needs .list_job .job1 {
    display: flex;
    justify-content: stretch;
    align-items: center;
    padding: 10px 0;
}

.list_job .job1 h5 {
    margin-right: 15px;
    font-size: 12px;
    background: #f5f5f5;
    padding: 8px 20px;
    font-weight: 600;
    margin-bottom: 0;
    padding-right: 5px;
}

.list_of_needs .list_job .job1 a.add_needs {
    background: #76bd43;
    border-radius: 50%;
    width: 30px;
    min-height: 30px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    color: #6a6a6a;
}

.add_needs:hover {
    background: #76bd43 !important;

}

.add_needs:hover .fa {
    color: white !important;
}

/** end **/
.details {
    margin: 15px;
    box-shadow: 8px 5px 14px -5px #ccc;
    background: #fff;
    padding: 5%;
}

.f_size {
    font-size: 14px;
}

.manage .details .job_details .text h6 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: #6a6a6a;
    margin: 0;
}

.manage .details .job_details .text a {

}

.manage .details .job_details .text a:hover {
    color: #007bff;
}

.details h3 {
    color: #6dbe46;
    font-size: 14px;
    margin: 0;
}

.manage a#box_linking {
    text-decoration: none;
    color: #212529;
}

.icon_img i {
    color: #76bd43;
    font-size: 14px;
}

.icon_img i.info {
    color: darkgray;
    padding: 3px;
}


.details h2 {
    font-size: 16px;
    margin: 0;
}

.icon_content {
    padding: 0px 30px;
}

#icon_with_text .reload_icon i {
    color: #f4f4f4;
}

.circle {
    text-align: center;
}


/** progress circle ***/


/*** progress circle end **/


span.icon_img {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 10px;
}

.icon_img .f_size {
    margin: 0 10px;
    font-weight: 700;
}

.job_details {
    display: flex;
    padding: 4% 0;
    cursor: pointer;
}

.icon_and_feed {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}


.social_icons {
    flex: 1 1 25%;
    text-align: center;
}

.social_icons .details p {
    font-weight: 700;
    text-transform: capitalize;
}

.feedback {
    flex: 1 1 25%;
}

div#icon_with_text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
}

.icon_with_text h3 {
    margin: 0;
}

.icon_with_text h2 {
    margin: 0;
}

.social_icons .icons span i {
    background: #cdcdcd;
    padding: 10px;
    width: 36px;
    text-align: center;
    margin: 3%;
    border-radius: 2px;
    color: #fff;
    transition: 0.6s all;
}

.social_icons .icons button {
    margin: 3% 2%;
}

.copy-url{
    color: #6dbe46;
    font-size: 14px;
    display: none;
}

.job_status{
    top: 3px;
    position: relative;
    paddingRight: 4px;
}

.pic_and_text {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #cdcdcd;
}

.account-menu ul {
    margin-bottom: 0 !important;
}

.username_text{
    font-size: 14px;
    display: block;
    text-transform: capitalize;
}

.username_text a {
    font-size: 13px;
    line-height: 0;
    color: #000000;
    text-decoration: none;
}

.username_text a:hover {
    color: #007bff;
}

.profile_user_login ul li a {
    font-size: 14px;
    text-decoration: none;
    color: black;
    display: block;
    padding: 0 16px;
}

.profile_user_login ul li a:hover {
    background-color: #E6E8EB;
}

.profile_user_login ul li {
    text-align: left;
    list-style-type: none;
    border-bottom: 1px solid #e6e8eb;
}

.social_icons .icons span i:hover {
    background: #76bd43;
}

.login_user_img {
    position: relative;
    cursor: pointer;
}

.profile_user_login {
    margin: 0;
    position: absolute;
    list-style: none;
    background: white;

    box-shadow: 0 0 0 1px rgba(99, 114, 130, .16), 0 8px 16px rgba(27, 39, 51, .08);
    right: 0;
    width: 210px;
    z-index: 1;
    border: 1px solid #e3e3e3;
    box-sizing: inherit;


    margin-top: 1px;
    background-color: #fff;
    border-radius: 4px;

}


.feedback .details p {
    font-weight: 700;
    text-align: center;
}

.feedback button {
    display: block;
    background: #76bd43;
    border: none;
    color: #fff;
    padding: 2% 15%;
    border: 2px solid #76bd43;
    text-transform: capitalize;
    margin-top: 10%;
    font-size: 14px;
    width: 100%;
    transition: 0.4s all;
}

.feedback a {
    color: #fff;
    text-decoration: none;
}

.feedback a:hover {


    color: #76bd43;
}

.feedback a:hover button {
    border: 2px solid #76bd43;
    background: #fff;
    color: #76bd43;
}

.feedback .details .content_feed button:hover span {
    color: #76bd43;
}

.content_feed {
    text-align: center;
}

.content_feed span {
    color: #cdcdcd;
    font-size: 14px;
}

.feedback .details .content_feed button span {
    margin-left: 10px;
    color: #fff;
}

.user_login {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user_login span i {
    font-size: 20px;
    font-weight: 700;
    color: #17a8dd;
    cursor: pointer;
	vertical-align:middle;

}


.details .job_details .text .img span {
    color: #c7c7c7;
    font-size: 13px;
}

.manage .details .job_details .user_img {
    padding-right: 5px;
}

.manage .details .job_details .user_img img
{
	border-radius:100%;
    width: 60px;
    height: 60px;
}

.modal_img img {
    border-radius: 100%;
}

.job_details:not(:last-child) {
    border-bottom: 1px solid #e3e3e3;
}

/*** sign up ***/
section.form_main {
    padding-top: 10%;
}

.form_main label.form-check-label {
    color: #7b7b7b;
    font-weight: bold;
}

.form_main .form-control {
    height: 41px;
    background: #fff;
    box-shadow: none !important;
    border: 2px solid #e0e0e0;
}

.form_main .form-control:focus {
    background: #e2e2e2;
}

.form_main .form-control, .btn {
    border-radius: 3px;
}

.form_main .signup-form form {
    color: #999;
    border-radius: 3px;
    margin-bottom: 15px;
    background: #f2f2f2;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 15px 30px;
}

.form_main .signup-form h2 {
    color: #696969;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 10px;
}

.form_main .signup-form hr {
    margin: 0 -30px 20px;
}

.form_main .signup-form .form-group {
    margin-bottom: 20px;
}

.form_main .signup-form input[type="checkbox"] {
    margin-top: 3px;
}

.form_main .signup-form .row div:first-child {
    padding-right: 10px;
}

.form_main .signup-form .row div:last-child {
    padding-left: 10px;
}

.signup-form .btn {
    font-size: 16px;
    font-weight: bold;
    background: #73c53c;
    border: none;
    min-width: 140px;
    border: 1px solid #73c53c;
    transition: 0.4s all;
}

.signup-form .btn:hover, .signup-form .btn:focus {
    background: #fff !important;
    color: #73c53c;
    border: 1px solid #73c53c;
}

/*** sign up content ***/
.sign_up_content {
    padding: 0% 0%;
    margin: 15px;
}

.sign_up_content h2 {
    color: #686868;
    margin-bottom: 0;
    font-size: 34px;
    font-weight: 400;
}

.sign_up_content p {
    margin-top: 40px;
    margin-bottom: 40px;
    font-size: 20px;
    color: #676767;
}

.sign_up_content ul li {
    list-style: none;
    color: #939393;
}

.sign_up_content li::before {
    content: '✓';
    font-size: 21px;
    font-weight: bold;
    margin-right: 10px;
    color: #75c441;
}


/** pricing css ***/
.table_container {
    width: 100%;
    padding: 3% 3% 0px 3%;
    border: 1px solid #ddd;
    margin: 5% 0;

}

.table_container table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.table_container th, td {
    text-align: left;
    padding: 10px 5px 10px 5px;

}

.table_container tr:nth-child(odd) {
    background-color: #f2f2f2
}

.table_container tr.titles {
    background: #fff !important;
}

.table_container tr.titles th {
    padding: 0% 0;
}

.titles th h4 {
    color: #79c343;
    font-weight: bold;
}

td.Plan {
    color: #707070;
    font-weight: bold;
    font-size: 18px;
}

tr.b_top {
    border-top: 1px solid #f2f2f2;
}

.price_link a {
    padding: 3% 7%;
    background: #79c343;
    color: #fff;
    border-bottom: 2px solid #5f9c33;
    font-size: 15px;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0.5px;

}

.price_link {
    text-align: center;
    margin-top: 8%;
}

td.check {
    font-weight: bold;
    color: #79c343 !important;
    font-size: 30px;
    padding: 0;
}

tr.b_top strong {
    color: #777777;
    font-size: 18px;
}

td:not(:last-child) {
    border-right: 1px solid #e3e3e3;
}

td:not(:first-child) {
    text-align: center;
    color: #777777;
}

th:not(:first-child) {
    text-align: center !important;
}

.sales_text {
    background: #f2f2f2;
    margin: 3% -35px 0px;
}

.sales_text p {
    margin: 0;
    padding: 1%;
    color: #707070;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.m_t a {
    padding: 4% 7%;
}

/*** profile ***/
section.profile {
    background: #f4f4f4;
    padding: 5%;

    margin-top: 100px;
}

.profile a.nav-link {
    text-transform: uppercase;
    font-weight: bold;
    color: #a1a1a1;
    font-size: 14px;
}

.profile a.nav-link.active {
    background: #7cc242 !important;
    border-color: #fff;
    color:white;
}

.nav-tabs {
    border-bottom: 1px solid #fff;
}

.profile .tab-content {
    background: #fff;
}

.share-button{
    background-color: transparent; border: none; padding: 0px; font: inherit; color: inherit; cursor: pointer;
}

.profile .Send_Trade_Offer a.trade_btn {
    background: #78bd43;
    color: #fff;
    padding: 5%;
    display: block;
    font-weight: 600;
    transition: 0.4s all;
}

.profile .Send_Trade_Offer a.trade_btn:hover {
    background: #fff;

    border: 1px solid #78bd43;
}

.profile .Send_Trade_Offer .Back_to {
    text-align: center;
    display: block;
    padding: 5%;
    color: #0ba7da;
    font-size: 16px;
    text-decoration: none;
}

.profile .Send_Trade_Offer {
    margin-top: 12%;
}

.profile .Send_Trade_Offer p {
    color: #909090;
    font-size: 14px;
}

.profile .Send_Trade_Offer h5 {
    color: #646464;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
}

.profile .work_images {
    display: flex;
}

.profile .work_images .img img {
    width: 100%;
}

.profile .work_images .img {
    margin: 0px 10px !important;
    box-shadow: 0px 8px 10px #000;
}

.profile .reviews {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #f4f4f4;
}

.profile .reviews .ratings {
    width: 30%;
    text-align: right;
}

.profile .reviews .reviews_content h4 {
    color: #78bd43;
    font-weight: 400;
    font-size: 16px;
}

.profile .reviews .reviews_content p {
    color: #a1a1a1;
    font-style: italic;
    font-size: 15px;
}

.profile .reviews .reviews_content {
    padding: 0px 8px;
}

.profile .reviews .ratings p {
    color: #a1a1a1;
    margin: 0;
    font-size: 14px;
}

.profile .reviews .ratings p {
    color: #a1a1a1;
    margin: 0;
}

.profile .reviews .stars span {
    color: #78bd43;
    font-size: 10px;
}

.profile .reviews .stars {
    text-align: right;
}

.profile .skills h5 {
    color: #646464;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 14px;
    margin-bottom: 15px;
}

.profile .skills .skils_name a {
    color: #646464;
    background: #f4f4f4;
    padding: 5px 10px;
    display: inline-block;
    text-decoration: none;
    text-transform: capitalize;
    font-size: 14px;
}

.profile .overview_text p {
    color: #909090;
    font-size: 14px;
}

.profile .overview_text {
    padding: 2% 0;
    margin-top: 10px;
}

.profile .skils_name {
    padding: 2% 0;
}

.profile .profile_tab {
    display: flex;
    align-items: center;
}

.profile_tab .profile_tab_content h4 {
    color: #646464;
    font-weight: 400;
    font-size: 20px;
}

.profile .profile_tab .profile_tab_content h4 {
    text-transform: uppercase;
    font-weight: 600;
    color: #6a6a6a;
    font-size: 14px;
}

.profile .profile_tab_content ul li {
    font-size: 14px;
}

.profile_tab_content p.designer {
    color: #0ba7da;
    margin: 0;
    font-size: 16px;
}

.tab-pane {
    height: auto;
}

.profile_tab_content ul {
    margin: 0;
}

.profile_tab_content ul li {
    list-style: none;
    display: inline-block;
    padding: 0px 10px 0px 0px;
    color: #646464;
}

.profile_tab_content ul li span {
    color: #78bd43;
    font-weight: bold;

}

.or_circle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px;
}

.or_circle span {
    background: #e3e3e3;
    border-radius: 50%;
    text-transform: uppercase;
    width: 35px;
    font-weight: bold;
    font-size: 12px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success_modal {
    flex-direction: column;
}

.success_modal .contact_info_content {
    text-align: center;
}

.success_modal .contact_info_content p {
    font-weight: 600;
}

.success_modal .contact_info_content h5 {
    color: #75bb3e;
    font-weight: 500;
    font-size: 36px;
}

.success_modal .contact_info_content h5.message {
    color: #000000;
    font-weight: normal;
    font-size: 20px;
}

.location_star {
    text-align: right;
}

.location_star .map_icon {
    margin-left: auto;
}

.location_star .map_icon {
    color: #a1a1a9;
    font-size: 14px;
}

.location_star .map_icon i {
    margin: 0px 5px;
}

.location_star .cerficate_icon {
    color: #a1a1a9;
    font-size: 14px;
}

.location_star .cerficate_icon i {
    margin: 0px 5px;
}

.location_star .stars span {
    color: #78bd43;
    font-size: 11px;
}

.location_star .stars {
    margin-top: 5px;
}

.location_star .stars span#count {
    color: #a1a1a9;
}


/**** timeline ***/
.timeline {

}

.timeline .reload span i {
    font-size: 7em;
    color: #12a2de;
}

.timeline_one h2.fs-title {
    font-size: 30px;
    text-transform: unset;
    letter-spacing: 0;
    font-weight: 600;
    color: #666666;
}


.timeline_one .trade_btn {


    border-radius: 5px !important;


    width: 130px !important;
    text-align: center !important;
    margin: 20px 10px !important;
    padding: 6px !important;


    transition: 0.4s all;
}

.custom_sel {
    max-width: 30%;
    margin: 30px auto;
}

.custom_prev {
    text-decoration: none;
    position: relative;
    display: inline-block;
    margin: 0 15px !important;
    background: #7ec249 !important;
    width: 90px !important;
    color: #fff !important;
    font-weight: bold !important;
    cursor: pointer !important;
    font-size: 15px !important;
    padding: 10px 0 !important;
}

.custom_next {
    position: relative;
    display: inline-block;
    margin: 0 15px !important;
    background: #7ec249 !important;
    width: 90px !important;
    color: #fff !important;
    font-weight: bold !important;
    cursor: pointer !important;
    font-size: 15px !important;
    padding: 10px 0 !important;
    text-decoration: none;
}

.custom_next:hover {
    text-decoration: none;
}


.custom_next::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 23.3px 0 20px 20px;
    border-color: transparent transparent transparent #79c24b;
    top: 0;
    right: -20px;
}

.custom_prev::before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 23.3px 20px 20px 0px;
    border-color: transparent #79c24b transparent #79c24b;
    top: 0;
    left: -20px;
}

span.prev {
    position: relative;
    margin: 0 15px !important;
    padding: 10px 0px;
    background: #7ec249;
}

span.prev::before {
    content: '';

    position: absolute;
    top: 0;
    right: 100%;
    width: 0;
    height: 0;
    border-color: transparent #7ec249 transparent transparent;
    border-style: solid;
    border-width: 21px;
}

.timeline_one textarea, input {
    font-size: unset !important;
}

.timeline_one textarea:focus, input:focus {
    border-color: unset !important;
}

h2.fs-title.double_color_h span {
    color: #12a2de;
}

input.maximum_width_input {
    max-width: 50%;
    display: block;
    margin: 0 auto;
}

.sub_title_val {
    font-weight: 600;
    color: #666666;
    font-size: 20px;
}

p.optional_text {
    color: #757575;
    font-size: 12px !important;
}

.group_of_text_input {
    display: flex;

    justify-content: space-around;
    align-items: center;
    margin: 15px auto;
    max-width: 55%;
}

.group_of_text_input input {
    margin-bottom: 0px !important;
}

.group_of_text_input span {
    width: 25%;
    text-align: left;
}

.image_uploader {
    width: 30%;
    margin: 0 auto;
}

.image_preview_main {
    border: 2px solid #e8e8e8;
    width: 70%;
    margin: 0 auto;
}

.image_preview {
    background: #e8e8e8;
    padding: 45px 10px;
    margin: 7px;
}


.upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.upload-btn-wrapper .btn {
    color: #fff;
    background-color: #7e7e7e;
    padding: 5px 15px;
    border-radius: 0px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 10px;
}

.upload-btn-wrapper button.remove_pic.btn {
    background: #e8e8e8;
    color: #7e7e7e;
}

.upload-btn-wrapper input[type=file] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    display: none;
}

.three_img_uploader {
    display: flex;
    margin: 30px auto;
}

.one_pic_upload {
    margin: 30px auto;
}

.image_preview_main {
    position: relative;
}

.image_preview_main input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    cursor: pointer;
}


.image_preview_main .image_preview p {
    color: #787878;
    font-size: 12px;
}

.image_preview_main .image_preview h5 {
    color: #787878;
    font-size: 14px;
}

.social_media_timeline img {
    margin: 0px 10px 15px 10px;
    width: 7% !important;
}

.two_link_box .fb_link .maximum_width_input {
    display: inline-block;
}

.two_link_box .fb_link label {
    width: 15%;
    font-size: 18px;
    margin: 0;
}

.two_link_box {
    margin: 30px auto;
}

section.timeline {
    padding: 0px 0;
}

.timeline_one .reload img {
    width: 130px;
}

fieldset {
    overflow: hidden;
}

.two_link_box input {
    margin: 10px auto !important;
}


/*form styles*/
#msform {
    text-align: center;
    position: relative;
    margin-top: 30px;
}

#msform fieldset {
    background: white;
    border: 0 none;
    border-radius: 0px;
    padding: 0px 30px;
    box-sizing: border-box;
    width: 80%;
    margin: 0 10%;
    margin-top: 90px;

    /*stacking fieldsets above each other*/
    position: relative;
}

/*Hide all except first fieldset*/
#msform fieldset:not(:first-of-type) {
    display: none;
}

/*inputs*/
#msform input, #msform textarea {
    padding: 8px 10px;
    border: 1px solid #e7e7e7;
    border-radius: 0px;
    margin: 30px auto;
    width: 100%;
    box-sizing: border-box;
    color: #000;
    font-size: 13px;
    border: 1px solid #ced4da;
    border-radius: .25rem;

}

#msform input:focus, #msform textarea:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid #ee0979;
    outline-width: 0;
    transition: All 0.5s ease-in;
    -webkit-transition: All 0.5s ease-in;
    -moz-transition: All 0.5s ease-in;
    -o-transition: All 0.5s ease-in;
}

/*buttons*/
#msform .action-button {
    width: 100px;
    background: #73c53c;
    color: white;
    border: 1px solid #73c53c !important;
    font-weight: bold;

    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
    font-size: 16px;
    font-weight: bold;


    border: 1px solid #73c53c;
    transition: 0.4s all;
}

#msform .action-button:hover, #msform .action-button:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #ee0979;
}

#msform .action-button-previous {
    width: 100px;
    background: #C5C5F1;
    font-weight: bold;

    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 10px 5px;
    margin: 10px 5px;
}

.inputs_group {
    margin: 30px auto;
}

.inputs_group input {
    margin: 0px auto !important;
}

#msform .action-button-previous:hover, #msform .action-button-previous:focus {
    box-shadow: 0 0 0 2px white, 0 0 0 3px #C5C5F1;
}

/*headings*/
.fs-title {
    font-size: 18px;
    text-transform: uppercase;
    color: #2C3E50;
    margin-bottom: 10px;
    letter-spacing: 2px;
    font-weight: bold;
}

.fs-subtitle {
    font-weight: normal;
    font-size: 13px;
    color: #666;
    margin-bottom: 20px;
}

/*progressbar*/
#progressbar {
    margin-bottom: 0px;
    overflow: hidden;
    /*CSS counters to number the steps*/
    counter-reset: step;
    width: 100%;
    display: flex;
}

#progressbar li {
    list-style-type: none;
    color: white;
    text-transform: uppercase;
    font-size: 9px;
    width: 100%;
    float: left;
    position: relative;
    letter-spacing: 1px;
}

#progressbar li:before {
    content: counter(step);
    counter-increment: step;

    line-height: 26px;
    display: block;
    font-size: 12px;
    color: #ccc;
    background: #ccc;

    margin: 0 auto 0px auto;
    height: 13px;
}

.first_timeline:before {
    border-top-left-radius: 10px 50%;
    border-bottom-left-radius: 10px 50%;
}

.last_timeline:before {
    border-top-right-radius: 10px 50%;
    border-bottom-right-radius: 10px 50%;
}


/*progressbar connectors*/
#progressbar li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: white;
    position: absolute;
    left: -50%;
    top: 9px;
    z-index: -1; /*put it behind the numbers*/
}

#progressbar li:first-child:after {
    /*connector not needed before the first step*/
    content: none;
}

/*marking active/completed steps green*/
/*The number of the step and the connector before it = green*/
#progressbar li:before, #progressbar li:after {
    background: #ccc9c9;
    color: transparent;
}

#progressbar li.active:before, #progressbar li.active:after {
    background: #7ec249;
    color: transparent;
}


/* Not relevant to this form */
.dme_link {
    margin-top: 30px;
    text-align: center;
}

.dme_link a {
    background: #FFF;
    font-weight: bold;
    color: #ee0979;
    border: 0 none;
    border-radius: 25px;
    cursor: pointer;
    padding: 5px 25px;
    font-size: 12px;
}

.dme_link a:hover, .dme_link a:focus {
    background: #C5C5F1;
    text-decoration: none;
}

/*** status page ***/

/** add alert modal ***/
.modal-body table {
    width: 100%;
}

.save_cancel {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.save_cancel a {
    border: none;
    background: #75bb3e;
    color: #fff;
    padding: 2% 8%;
    border-radius: 5px;
    text-decoration: none !important;
}

.contact_info_main {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0px;
}

.c_info_custom .modal-body1 button {
    padding: 5px 10px;
    border: none;
    color: #80807E;
    opacity: 1;
}

.c_info_custom .modal-content {
    border: none;
    border-radius: 0;
}

.contact_info_main .contact_info_img {
    margin: 0px 15px;
}

.contact_info_content h5 {
    color: #75bb3e;
}

.contact_info_content p {
    font-weight: 500;
    margin: 0px 0px 8px 0px;
}

.status .trades_left_main span {
    border-bottom: 0px;
}

.status .trades_left_main {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e3e3e3;
}

.status .trades_left_main p.trades_left {
    font-style: italic;
    text-decoration: underline;
    color: #c6c6c6;
}

.status .job_details .text h6 {
    text-transform: uppercase;
    font-weight: 400;
}

.status .button_accept a {
    background: #75bb3e;
    color: #fff;
    text-transform: capitalize;
    display: block;
    padding: 10px 20px;
    border-radius: 5px;
    margin: 0px 10px;
    font-size: 14px;
    text-decoration: none;
    transition: 0.4s all;
}

.status .button_accept a:hover {
    border: 1px solid #75bb3e;
    color: #75bb3e;
    background: transparent;
}

.status .button_accept a span {
    margin: 10px;
}

.status .button_accept.ml-auto {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_accept #decline {
    background: red;
    border: 1px solid red;
    transition: 0.4s all;
}

.button_accept #decline:hover {
    background: transparent;
    border: 1px solid red;
    color: red;
}

.status .contact_info a {
    margin: 0px 10px;
    background: #15a5da;
    color: #fff;

    display: block;
    font-size: 14px;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: 0.4s all;
}

.status .view_contact_info {
    border: 1px solid #15a5da;
}

.status .view_contact_info:hover {
    border: 1px solid #15a5da;
    color: #15a5da;
    background: transparent;
}

.status .contact_info a span {
    margin: 10px;
}

.status .pending a {
    color: #c6c6c6;
    font-size: 14px;
    text-transform: capitalize;
    margin: 0px 10px;
}

.status .pending span {
    margin-right: 10px;
}

.status .contact_info .contact_review {
    padding: 5px;
    color: #8c8c8c;
    background: #fff;
    text-align: center;
    text-decoration: underline;
}

.modal-body {
    text-align: center;
    width: 100%;
    position: unset;
}

.modal-body form textarea {
    width: 90%;
    border-radius: 5px;
    padding: 10px;
}

button.add-my-review {
    background: #75bb3e;
    color: #fff;
    text-transform: capitalize;
    padding: 15px 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    margin: 5% 0;
}

.add-my-review span {
    margin: 10px;
}

.modal-body h2 {
    color: #75bb3e;

    font-weight: 400;
}

.modal-body .stars span {
    color: #D4AF37;
    font-size: 24px;
    margin: 0px 3px;
}

.modal-body .stars {
    padding: 2% 0px 6% 0%;
}

.modal-header {
    border: none;
}

.modal_img {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-dialog {
    top: 5%;
}

/**** search page ***/
#profile_box_link {
    text-decoration: none;
    color: unset;
    display: flex;
}

section.select_search {
    padding: 3% 0%;
    background: #75bb3e;
    position: relative;
    z-index: 9;
}

section.select_search:after {
    border-right: solid 10px transparent;
    border-left: solid 10px transparent;
    border-top: solid 10px #75bb3e;
    transform: translateX(-50%);
    position: absolute;
    z-index: 1;
    content: '';
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    display: none;
}

.select_search .center_select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.select_search .form-group {
    margin-bottom: 0;
}

.select_search button.search_btn.btn {
    background: #fff;
    border-radius: 50%;
    color: #75bb3e;
}

.select_search button.search_btn.btn {
    background: #fff;
    border-radius: 50%;
    color: #75bb3e;
}

.select_search h2 {
    color: #fff;
    font-size: 21px;
    padding: 0% 0% 1% 0;
    font-weight: 400;
    width: 100%;
    text-align: center;
}

.search .search_listing {
    display: flex;
    justify-content: space-between;
    padding: 3% 0px;
}

.search .search_listing h5 {
    color: #4a4a4a;
    font-weight: 400;
    font-size: 18px;
}

.search .search_main {
    background: #fff;
    padding: 2%;
    display: flex;
    background: #fff;
    margin-bottom: 3%;
    box-shadow: 8px 5px 14px -5px #ccc;
    cursor: pointer;
}

.search .search_main .profile_tab_content h4 {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #6a6a6a;
}

.search .search_main .profile_tab_content ul li {
    font-size: 14px;
}

.search .country_certify {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.search .search_main .profile_tab_content .description {
    margin: 3% 0% 0% 0%;
    color: #c9c9c9;
    max-width: 80%;
    font-size: 14px;
}

.search_main .search_main .icon_box span {
    color: #c9c9c9;
}

.search .search_main .country_certify .stars span {
    color: #75bb3e;
    font-size: 10px;
}

.search .search_main .country_certify .stars .count {
    color: #c9c9c9;
    font-size: 12px;
}

.search .country_certify .icon_box span {
    color: #c9c9c9;
    display: block;
    font-size: 14px;
}

.remove_underline {

}

.search .country_certify .icon_box span i {
    margin: 0px 5%;
}

.search .not_found h3 {
    margin: 5% 0;
    font-weight: 400;
}

/*** sidebar filter list ***/
.filterlist_heading h5 {
    padding: 10% 0;
    color: #4a4a4a;
    font-weight: 400;
}


.filter_list .dropdown.bootstrap-select {
    width: 100% !important;
}

.filter_list button.btn.dropdown-toggle.btn-light.bs-placeholder {
    background: #ececec;
    border: none;
    padding: 4% 5%;
}

.search_alert {
    background: #f8f8f8;
    border: 3px solid #e4e4e4;
    padding: 7%;
}

.search_alert .alert_box_content h4 {
    color: #75bb3e;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: bold;
}

.search_alert .alert_box_content p {
    color: #868686;
    font-size: 14px;
}

.search_alert .alert_box_content a {
    color: #868686;
    display: block;
    text-decoration: underline;
    font-size: 12px;
    padding: 4% 0px;
}

.search_alert .alert_box_content .add-alert_btn {
    border: none;
    background: #75bb3e;
    color: #fff;
    padding: 4% 10%;
    font-size: 16px;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    border: 2px solid #75bb3e;
}

.search_alert .alert_box_content .add-alert_btn:hover {
    border: 2px solid #75bb3e;
    color: #75bb3e;
    border: 2px solid #75bb3e;
    background: none;
}

.search_alert .alert_box_content .add-alert_btn i {
    margin-right: 8px;
}


/**** manage job profile  **/

.manage_job_bg {
    background: #fff;

}

.file-upload-wrapper {
    position: relative;
    overflow: hidden;
}

.file-upload-wrapper input {
    opacity: 0;
}

.file_upload {
    display: flex;
    padding: 2% 0;
}

.newProfilrTab {
    display: flex;
    border: 3px solid #c9c9c9;
    margin: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 5%;
    position: relative;
    overflow: hidden;
}

.newProfilrTab:hover {
    color: white;
    background: #72c244;
    border: #72c244 3px solid;


}

.newProfilrTab:hover i {
    color: white;


}

.newProfilrTab i {
    color: #72c244;
}

.file-upload-wrapper {
    display: flex;
    border: 3px solid #c9c9c9;
    margin: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.file-upload-wrapper span {
    color: #c9c9c9;
}

.file-upload-wrapper h4 {
    color: #c9c9c9;
    text-transform: uppercase;
    font-size: 15px;
}

.manage_job {
    padding: 2%;
    border-bottom: 1px solid #c9c9c9;
}

.manage_job span {

    font-weight: 600;
    font-size: 18px;
}

.manage_job .addAlert {
    padding: 5px 10px;
    font-size: 13px;
    margin-left: 0;
    float: right;
}

.manage_job .addAlert i {
    color: white;
    vertical-align: inherit;
    font-size: 15px;
}

.addAlert:hover .fa {
    color: #72c244 !important;
}


.manage_job span i {
    color: #72c244;
    font-size: 25px;
    margin: 0px 10px 0px 0px;

}

/*** happen page css ***/
.what_happen_now {
    width: 100%;
    text-align: center;
    margin-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.what_happen_now h2 {
    font-size: 40px;
    color: #676767;
    margin-bottom: 25px;
}

.what_happen_now p {
    color: #999999;
    margin: 0;
    line-height: 1.9;
    font-weight: 500;
    font-size: 15px;
}

.what_happen_now a {

    width: 175px;
    display: block;
    margin: auto;
    color: #fff;
    margin-top: 25px;
    font-weight: 500;
    font-size: 18px;
    background: #75bb3e;
    padding: 10px 20px;
    text-decoration: none;
    border: 2px solid #75bb3e;
    border-radius: 5px;
}

.what_happen_now a:hover {
    color: #75bb3e;
    background: none;

}


/*** thanks page css **/
.thanks {
    width: 100%;
    text-align: center;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.thanks h2 {
    font-size: 36px;
    color: #676767;
    margin-bottom: 5px;
}

.thanks p {
    color: #999999;
    margin: 0;
    line-height: 1.9;
    font-weight: 500;
    font-size: 16px;
}

.thanks a {
    color: #fff;
    margin-top: 10px;
    font-weight: 500;
    font-size: 18px;
    background: #79c24b;
    padding: 5px 20px;
    text-decoration: none;
    position: relative;
}

.thanks a::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 18.3px 0 19px 20px;
    border-color: transparent transparent transparent #79c24b;
    top: 0;
    right: -20px;
}

/*** manage job profile ***/
.your_profile_headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

.your_profile_headline h6 {
    border-right: 1px solid #e3e3e3;
    width: 50%;
    padding: 20px 0;
    margin: 0;
}

.your_profile_headline span {
    width: 50%;
    padding: 20px 0;
    margin: 0;
}

.editable td:nth-child(1) {
    width: 20%;
    font-weight: bold;
    color: #6c6c6c;
}

.editable tr:nth-child(1) {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

.editable tr:nth-child(3) {
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #e3e3e3;
}

.editable tr:nth-child(10) {
    border-bottom: 1px solid #e3e3e3;
}

.editable tr:nth-child(16) {
    border-top: 1px solid #e3e3e3;
}

.editable td:nth-child(2) {
    text-align: left;
}

.Update_profile {

}

.Update_profile .Update_profile_btn.btn {
    background: #78bd43;
    color: #fff;
    padding: 2%;
    display: block;
    text-transform: uppercase;
    font-weight: 600;
    max-width: 200px;
    margin: 0 auto;
}

.Update_profile {
    margin: 60px 0;
}


/*** settings page css ***/
.settings_page .tab-pane {
    height: auto !important;
}

#tab3 .table_container {

    margin: 0%;
    overflow-x: scroll;
}

#tab4 .Update_profile {
    padding: 32px 0;
}

.tab-pane {
    height: auto;

}

#tab4 .manage_job_profile {
    margin: 0;
}

/**** login form ***/
.login_form {
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login_form form {
    width: 100%;
    padding: 20px;
    background: #e4e4e4;
}

.login_form .full_login {
    width: 100%;
    border: 1px solid #73c53c;
    border: 1px solid #73c53c;
    transition: 0.5s;
    text-transform: uppercase;
    background-color: #73c53c;
}

.login_form .full_login:hover {
    background: transparent;
    color: #73c53c;
}

/**** responsive design start *****/
@media only screen and (min-width: 1000px) {
    .table-responsive {
        overflow: hidden !important;
    }
}

@media only screen and (max-width: 550px) {

    .latest_jobs .user_img, .statsRes .job_details .user_img {
        display: none;
    }

    .statsRes .job_details {
        display: block;
    }

    .statsRes .button_accept.ml-auto {
        flex-direction: inherit !important;
    }

    .latest_jobs .remove_underline {
        font-size: 15px;

    }

    .manage .details .job_details .text a {
        font-size: 13px;
    }


}


@media only screen and (max-width: 1200px) {
    .social_icons {
        flex: 1 1 100%;
    }

    /*** profile work **/
    .profile .reviews .reviews_content {
        width: 70%;
    }

    .profile .Send_Trade_Offer {
        margin-top: 14%;
    }
}


.mobile_menu {
    display: none;
}

@media only screen and (max-width: 1199px) {
    .status .button_accept.ml-auto {
        flex-direction: column;
    }

    .status .button_accept a {
        margin: 10px 10px;
    }
}

@media only screen and (max-width: 1024px) {
    .search .country_certify {
        width: 30%;
    }

    /*** profile work **/
    .profile .reviews .reviews_content {
        max-width: 60%;
        margin: 0 auto;
    }

    .profile .profile_tab_content {
        max-width: 50%;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 991px) {
    .bottom_nav {
        display: block;
    }
    .active a {
        color: #007bff !important;
    }


        .top_nav {
            display: none;
        }

    .sidenav .nav {
        display: none !important;
    }

    .notidropdown .list_value {
        padding-left: 23px !important;
    }
}

@media only screen and (max-width: 992px) {
    .editable td:nth-child(1) {
        width: 50%;
    }

    .nav_section .navbar-nav .nav-item .nav-link {
        text-align: center;
    }

    .manage {
        flex: 1 1 100%;
    }

    .icon_and_feed {
        width: 100%;
    }

    .social_icons {
        flex: 1 1 100%;
    }

    .feedback {
        flex: 1 1 100%;
    }

    .social_icons .details {
        text-align: center;
    }

    .social_icons .icons span i {
        margin: 0% 1% 0px 0%;
    }

    .feedback button {
        padding: 2% 6%;
        margin-top: 3%;
    }

    .feedback .details {
        padding: 5% 3%;
    }

    /*** profile work **/
    .profile .profile_tab_content {
        max-width: 100%;
        margin: 0 auto;
        text-align: center;
    }

    .profile .profile_tab {
        flex-direction: column;
        justify-content: center;
    }

    .profile .location_star.ml-auto {
        margin-left: unset !important;
        margin-top: 20px;
    }

    .profile .tab-pane {
        height: auto;
    }

    .profile .tab-content {
        background: #fff;
        padding-bottom: 25px;
    }

    .profile .reviews {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .profile .reviews .reviews_content {
        width: 100%;
        margin: 7px auto;
        text-align: center;
    }

    .profile .Send_Trade_Offer {
        margin-top: 16%;
    }
}

@media only screen and (max-width: 768px) {
    .profile .Send_Trade_Offer {
        margin-top: 21%;
    }
}

@media only screen and (max-width: 767px) {

    .file-upload-wrapper h4{
        font-size: 9px;
    }
    /*** search page ***/
    .filter_list button.btn.dropdown-toggle.btn-light.bs-placeholder {
        padding: 2%;
    }

    .filterlist_heading h5 {
        padding: 2% 0;
    }

    .search_alert .alert_box_content button {
        padding: 2% 4%;
    }

    .search .search_main {
        flex-direction: column;
    }

    .search .search_main .profile_tab_content {
        text-align: left;
    }

    .search .search_main .profile_tab_content .description {
        margin: 3% 0;
        max-width: 100%;
        display: none;
    }

    .search .country_certify {
        width: 100%;
        text-align: center;
        display: none;
    }

    .search .search_main .client_img {
        display: none;
    }

    .profile_tab_content p.designer {
        font-size: 13px;
    }

    .search .search_main .profile_tab_content ul li {
        font-size: 11px;
    }

    .search .country_certify .icon_box span i {
        margin: 0 2%;
    }

    #profile_box_link {

        flex-direction: column;
    }

    .status .job_details {

    }

    .status .manage .details .job_details .user_img {
        text-align: center;
        text-align: left;
    }

    .status .job_details .text {
        text-align: center;
        text-align: left;
        padding: 20px;
    }

    .status .button_accept.ml-auto {
        margin: unset !important;
    }

    .pending.ml-auto {
        margin: unset !important;
        text-align: center;
    }

    .contact_info.ml-auto {
        margin: unset !important;
    }

    .status .contact_info a {
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 575px) {
    /*** search page ***/
    .center_select .dropdown.bootstrap-select.form-control {
        margin: 8px 0;
    }

    .center_select .search_icon {
        margin: 8px 0px;
    }

    .select_search h2 {
        text-align: center;
    }

    .search .search_main {
        padding: 3% 6%;
    }

    /*** profile work **/
    .profile a.nav-link {
        text-transform: uppercase;
        font-weight: normal;
        color: #a1a1a1;
        width:140px;
    }

    /*** status **/
    .status .icon_img p {
        font-size: 12px;
    }


}

.status .trades_left_main p.trades_left {
    font-size: 13px;
}

@media only screen and (max-width: 320px) {
    a.navbar-brand img {
        max-width: 150px;
    }

    .job_status{
        top: 5px;
    }

    .job_details {
        padding: 10% 0;
        flex-direction: column;
    }

    .job_details .text {
        text-align: center;
        padding: 10px 0;
    }

    .job_details .user_img {
        text-align: center;
    }

    /*** profile page **/
    .profile .work_images {
        display: flex;
        flex-direction: column;
    }

    .profile .work_images .img {
        margin: 5px 10px !important;
        box-shadow: 0px 8px 10px #000;
    }

    .profile .skills .skils_name a {
        display: block;
        margin: 10px 0;
        padding: 10px 5px;
    }
}


.notification_drp {
    position: relative;


}


.notification_drp ul {
    list-style: none;
    height: 350px;
    overflow-y: auto;
}

.notification_drp ul li {
    padding: 10px 10px;
}

.notification_drp ul li:hover {
    background-color: #E6E8EB;
}

.notidropdown {
    z-index: 100;
    box-shadow: 0 0 0 1px rgba(99, 114, 130, .16), 0 8px 16px rgba(27, 39, 51, .08);
    margin: 0;
    position: absolute;
    list-style: none;
    background: white;


    right: 0;
    width: 210px;
    z-index: 1;
    border: 1px solid #e3e3e3;
    margin-right: 0;
    max-width: 380px;
    width: 380px;


    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    min-width: 160px;
    outline: none;

}


.notification_title {
    padding: 10px 10px;
    text-align: left;
    border-bottom: 1px solid #e6e8eb;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;

}

@media screen and (max-width: 768px) {
    .notidropdown {


    }

}

@media screen and (max-width: 600px) {
    .notidropdown {


        width: 303px;
    }

}

@media screen and (max-width: 769px) {


    .mobileInputs input, .mobileInputs textarea {
        width: 65% !important;
    }

    .mobileInputs .group_of_text_input span, .mobileInputs label {
        width: 33% !important;

        display: inline-block;
    }


    .what_happen_now h2 {
        font-size: 26px !important;
    }

    .social_media_timeline img {
        width: 20% !important;
    }

    .image_uploader {
        width: 50%;
    }

    .image_preview_main .image_preview p {
        margin-bottom: 0;
    }

    .image_preview_main {
        width: 100%;
    }

    .mobile_preview {
        padding: 43px 6px !important;
    }

    .image_preview {
        padding: 8px 1px;
        margin: 2px;
    }

    .two_link_box .fb_link label {
        width: 100%;
    }

    .group_of_text_input {
        max-width: 100%;
        display: block;
    }

    input.maximum_width_input {
        max-width: 100%;
    }

    .two_link_box .fb_link .maximum_width_input {
        text-align: center;
    }

    .custom_sel {

        max-width: 100%;
    }


    .timeline_one h2.fs-title {
        font-size: 16px;
    }

    #msform fieldset {
        padding: 0px 0px;
        margin-top: 30px;
    }

    .list_job .job1 h5 {
        font-size: 9px;
    }

}

@media screen and (max-width: 480px) {


    .list_job .job1 h5 {
        padding: 5px 3px;
        margin-right: 3px;

    }


    .notidropdown {

        width: 218px;
    }

}


.switch-label-new {
    display: block;
    margin: 12px auto;
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 60px;
    height: 25px;
    padding: 3px;

    -moz-border-radius: 18px;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    cursor: pointer;
}

.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    background: #7cc242;
    -moz-border-radius: inherit;
    -webkit-border-radius: inherit;
    border-radius: inherit;
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
    -webkit-transition-property: opacity background border;
    -moz-transition-property: opacity background border;
    -o-transition-property: opacity background border;
    transition-property: opacity background border;
}

.switch-label:before, .switch-label:after {
    position: absolute;
    top: 50%;
    margin-top: -.5em;
    line-height: 1;
    -webkit-transition: inherit;
    -moz-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    font-size: 12px;
    font-weight: 600;
}

.switch-label:before {
    content: attr(data-On);
    right: 29px;
    color: white;
}

.switch-label:after {
    content: attr(data-Off);
    left: 26px;
    color: black;
    opacity: 0;
}

.switch-input:checked ~ .switch-label {
    background: white;
    border: 1px solid grey;

}

.switch-input:checked ~ .switch-label:before {
    opacity: 0;
}

.switch-input:checked ~ .switch-label:after {
    opacity: 1;
}

.switch-handle {
    position: absolute;
    top: 6px;
    left: 35px;
    width: 18px;
    height: 18px;
    background: white;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
}

.switch-handle:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -6px 0 0 -6px;
    width: 12px;
    height: 12px;
    background: #f9f9f9;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    -moz-box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    -webkit-box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
    background-image: -webkit-linear-gradient(top, #eeeeee, white);
    background-image: -moz-linear-gradient(top, #eeeeee, white);
    background-image: -o-linear-gradient(top, #eeeeee, white);
    background-image: linear-gradient(to bottom, #eeeeee, white);
}


.switch-input:checked ~ .switch-handle {
    background: #7cc242;
    left: 10px;
    -moz-box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
    box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.switch-input:checked ~ .switch-handle:before {
    background: #7cc242;
}

.switch-green > .switch-input:checked ~ .switch-label {
    background: #7cc242;
}


#add-alert .modal-header, #manage-alert .modal-header {
    font-weight: bold;
    color: #6c6c6c;
}

#add-alert .modal-body {
    padding-top: 0;

}


.notidropdown li {
    overflow: hidden;
}

.notidropdown .user_img {
    width: 10%;
    float: left;
}

.notidropdown .user_img img {
    border-radius: 100%;
}

.notidropdown .list_value {
    width: 90%;
    float: left;
    padding-top: 5px;
    padding-left: 10px;
}

.notidropdown ul li {
    position: relative;
}

.notidropdown ul li .date {
    position: absolute;
    display: none;
    right: 14px;
    /* top: 0; */
    bottom: 14px;
    width: 50px;
    text-align: right;
    /* height: 50px; */
    /* vertical-align: initial; */
    background: #E6E8EB;
    color: #637282;
}

.notidropdown ul li:hover .date {
    display: block;
}

.notidropdown ul li a {
    text-decoration: none;
    color: #000 !important;
}

.login_user_img img {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 100%;
}

.manage_table tr {

    border-top: 1px solid #e3e3e3;


}

.manage_table td, .manage_table th {
    text-align: left !important;
    padding: 5px;
    font-size: 13px;
}

.delete_alert {
    width: 25px;
    height: 19px;
    float: right;
    font-size: 15px;
    color: red;
}

.delete_alert i {
    display: none;
    cursor: pointer;
}

.trade_alert:hover .delete_alert i {
    display: block;
}

.cut_need {
    opacity: 0;
    margin-left: 5px;
}

.job_prof:hover .cut_need {
    opacity: 1;
}

.Send_Trade_Offer .trade_btn:hover {
    color: #78bd43 !important;
}

.Send_Trade_Offer .trade_btn.disabled {
    background-color: #A8A9A5 !important;
}

.Send_Trade_Offer .trade_btn.disabled:hover {
    background-color: #ffffff;
}

.notification_table {
    margin-top: 10px;
}

.anually {
    font-size: 13px;
}

.edit_profile_i {
    float: right;
    opacity: 0;
}

.edit_profile_i .fa-times-circle {
    color: red;
}

.edit_profile_i .fa-check-circle {
    color: #72c244;
}

.editable_cont:hover .edit_profile_i {
    opacity: 1;
}

.profile_tab_content {
    margin-top: 10px;
    margin-left: 10px;
}

.search_main .stars {
    cursor: pointer;
}

.dropdown-toggle {
    border: none !important;
}

.setting_table input {
    border: none;
    padding-left: 17px;
}

.setting_table table, textarea {
    width: 100%;
}

.select_search .col-xs-6, .select_search .col-xs-3 {
    width: auto !important;
}

.show_mobile {
    display: none !important;
}

.side_filter {
    padding-left: 40px !important;
}

@media only screen and (min-width: 767px) {
    .filter_list {
        display: block !important;
    }
}

@media only screen and (max-width: 767px) {
    .show_mobile {
        display: block !important;
    }

    .show_desktop {
        display: none;
    }

    .filter_list {
        display: none;
    }

    .mobile_filter {
        background: #75bb3e;
        color: white;
        padding: 6px;
        border-radius: 5px;
        margin-top: 10px;
        display: inline-block;
        font-size: 14px;
        cursor: pointer;
        display: block !important;
        margin: 0 !important;
    }

    .web_filter {
        display: none;
    }


}

.mobile_filter {
    display: none;
}

.nav-up {
    top: -50%;
}

@media only screen and (max-width: 991px) {
    .nav_section .navbar-brand {
        display: none;
    }

    .web_menu {
        display: none;
    }

    .mobile_menu {
        display: flex !important;
    }

    .notidropdown {
        right: 0 !important;
    }
}

@media only screen and (max-width: 545px) {
    .manage_job_profile_res .manage_job_bg {
        width: 500px;
    }

    .searchImage img {
        width: 144px;
        height: 30px;
    }
}

@media only screen and (max-width: 500px) {
    .manage_job span {
        font-size: 16px;
    }

    .manage_job span i {
        font-size: 16px;
    }

    .manage_job .addAlert {
        margin-top: 6px;
        float: none;
        display: block;
    }
}

.searchImage {
    cursor: pointer;
}

.latest_jobs {
    height: 500px;
    overflow-x: auto;
    padding: 0;
}

.fixedLatest {
    padding: 0% 5% 5% 5%;
}

.search_main .stars {
    margin: 0 5%;
    margin-right: 0;
    margin-top: 60px;
}

.notification_heading {
    font-weight: 600;
}

.alert_notification {
    margin-top: 40px;
}


.logsign_pass {
    position: absolute;
    top: 13px;
    right: 10px;

}

.logsign_relative {
    position: relative;
}

.search_main .country_certify {
    text-align: right;
    justify-content: normal;
}

.search_main .designer u {
    text-decoration: none;
}

.container-fluid {
    padding-left: 40px !important;
    padding-right: 40px !important;
}

@media only screen and (max-width: 991px) {
    .container-fluid {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

}
.file-upload-wrapper {
    width: 33%;
}

@media only screen and (max-width: 452px) {

    .editable td:nth-child(1) {
        width: 40%;
    }

    .job_status{
        top: 5px;
    }

    .tab-content > .tab-pane {
        padding: 20px 5px !important;
    }

    .fa-4x {
        display: none !important;
    }



    .bootstrap-select .dropdown-toggle .filter-option-inner-inner {
        font-size: 12px;
    }

    .table_container th, td {
        font-size: 12px;
    }

    .file-upload-wrapper h4 {
        font-size: 9px;
        margin: 0;
    }

    .file-upload-wrapper input {
        display: none;
    }


    .newProfilrTab {
        font-size: 9px !important;
        padding: 6% 14% !important;
        margin: 2px;
    }

    .manageSettings {
        width: 290px;
    }

    .switch-label-new {

    }

    .profile .profile_tab_content ul li {
        font-size: 11px;
    }

    .toggle-set {
        font-size: 10px !important;
    }

    .location_star .map_icon, .location_star .cerficate_icon {
        float: left;
        font-size: 11px;
    }

    .location_star .stars {
        float: left;
        margin-left: 11px;
        margin-top: 0;
    }

    .location_star .stars span {


        vertical-align: super;
    }
}


.addAlert {
    float: right;
    padding: 3px 20px;
    background: #75bb3e;
    color: white;
    border-radius: 100px;
    font-size: 13px;
    cursor: pointer;
    border: 2px solid #75bb3e;
    margin: 0rem 0rem 0rem auto;
}

.addAlert i {
    margin-right: 10px !important;
}

.addAlert:hover {
    background: none;
    border: 2px solid #75bb3e;
    color: #75bb3e;
}

.manageSettings {
    width: 100%;
}

.tradeHire {
    border-radius: 5px !important;
    width: 130px !important;
    text-align: center !important;
    margin: 20px 10px !important;
    padding: 6px !important;
    font-weight: bold;
    border: 1px solid #73c53c;
    transition: 0.4s all;
    background: #73c53c;
    color: white;
}


.skip {
    display: inline-block;
    margin-top: 15px;
    color: #0ca4de !important;

    background-color: white;
    cursor: pointer;
}

.skip:hover {
    text-decoration: underline !important;
}

.changePassword {
    color: white;
    background: #7cc242 !important;
    border: none;
    padding: 5px 15px;
    border-radius: 100px;
}

.hidePassword {
    display: none;
}

button:focus {
    outline: none;
}

.feedback .details, .social_icons .details {

    height: 155px;
}

.fixed_top {
    padding: 5% 5% 1% 5%;
    position: sticky;
    top: 0;
    background: white;
}

.disabled {
    pointer-events: none;
    background: lightgray !important;

}

.disabled:before {
    pointer-events: none;

    border-color: transparent lightgray transparent lightgray !important;

}

.location_star_status {
    text-align: center;
}

.location_star_status .fa-star:hover {
    color: #D4AF37;
}

.modal-body {
    padding-top: 0px;
}

.leave_coment {
    margin-top: 10px;
}

.toggle-set {
    font-size: 14px;
}

.email_when {
    font-size: 12px;
    color: #637282;
}

.navbar-brand {
    margin-right: 0rem !important;
}

#overlay {
    position: fixed; /* Sit on top of the page content */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
}

.needSearch {
    border: none;
    border-bottom: 1px solid lightgrey;
    width: 100%;
}

.needSearch:focus {
    outline: none;
    border-bottom: 1px solid lightgrey !important;


}

.bootstrap-select > .dropdown-toggle {
    color: #777777 !important;
}

.tab-content > .tab-pane {
    padding: 20px;
}

.manage_noti {
    padding: 20px;
}

.setting_table .fa-info-circle {
    color: darkgray;
}

.setting_table .fa-info-circle:hover {
    color: gray;
}

.user_img_100 {
    max-width: 100px;
    max-height: 100px;
    border-radius: 100%;
}

.err_msg {
    color: darkred;
    font-size: 13px;
}

.err_msg_blk {
    color: darkred;
    font-size: 13px;
    display: inline-block;
    margin-left: 5px;
}

.pac-container {
    background-color: #FFF;
    z-index: 20;
    position: fixed;
    display: inline-block;
    float: left;
}
.modal{
    z-index: 20;
}
.modal-backdrop{
    z-index: 10;
}
.modal-dialog input
{
	height:auto !important;
	margin:0 !important;
}
.client_img img{
border-radius:100%;
}

.css-b8ldur-Input{
    line-height: 0 !important;
}
/*.sl_serv_srh .css-1g6gooi {
    height: 0 !important;
}*/

.seacrh_item{
	margin-bottom:10px;
}

.form-style
{
	background:white !important;
}
/* job4job css */

.sl_serv_srh .css-1g6gooi {
    line-height: 1!important;
}

.toggle-password{
    position: absolute;
    top: 5px;
    right: 5px;
}
.toggle-password2{
    position: absolute;
    top: 12px;
    right: 5px;
    color: #777777;
}

.password-main{
    position: relative;
}

textarea{
    padding: 5px 8px;
}

.no_padding {
    padding:0 !important;
}